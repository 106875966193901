import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "grid grid-cols-12 gap-6"
}
const _hoisted_2 = { class: "col-span-12 mt-8" }
const _hoisted_3 = { class: "intro-y flex items-center h-10" }
const _hoisted_4 = { class: "font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_order_bike = _resolveComponent("order-bike")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$user.Id)
      ? (_openBlock(), _createBlock(_component_order_bike, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, "當前使用者：" + _toDisplayString(_ctx.$user ? _ctx.$user.Name : ''), 1)
            ])
          ])
        ]))
  ]))
}